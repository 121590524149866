@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Roboto", Tahoma, Geneva, Verdana, sans-serif; ;
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #DDD;
  height: 100vh;
}

#root {
    height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: "Roboto";
    /* src: url("assets/font/Roboto-Thin.ttf"); */
    src: url("assets/font/HN.ttf");
}